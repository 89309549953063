import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bookingService, surgeonService } from "../../../../../../services";

const BasicDetails = ({ onSave, validationErrors }) => {
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedHospitalData, setSelectedHospitalData] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [hospitalAddress, setHospitalAddress] = useState([]);
  const [hospitalDepartment, setHospitalDepartment] = useState([]);
  const [selectedSurgeon, setSelectedSurgeon] = useState([]);
  const [surgeons, setSurgeons] = useState([]);
  const [bookingInfo, setBookingInfo] = useState({
    pickup_location_checkbox: false,
    pickup_location_text: "",
    note: "",
    delivery_date: null,  
  });

  const handleSave = () => {
    const data = {
      hospitalId: selectedHospital?.value,
      hospital_address: hospitalAddress.find((addr) => addr.checked)?.name,
      hospital_department: hospitalDepartment.find((dept) => dept.checked)
        ?.name,
      surgeonData: selectedSurgeon.map((surgeon) => surgeon.value),
      pickup_location_checkbox: bookingInfo.pickup_location_checkbox,
      pickup_location_text: bookingInfo.pickup_location_text,
      note: bookingInfo.note,
      delivery_date: bookingInfo.delivery_date?bookingInfo.delivery_date :"",
    };
    onSave(data);
  };

  useEffect(() => {
    handleSave();
  }, [
    selectedHospital,
    hospitalAddress,
    hospitalDepartment,
    selectedSurgeon,
    bookingInfo,
  ]);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await bookingService.getHospitalsList({ search: "" });
        const hospitalOptions = response.data.map((hospital) => ({
          value: hospital._id,
          label: hospital.name,
        }));
        setHospitals(hospitalOptions);
      } catch (err) {
        console.error("Error fetching hospitals:", err);
      }
    };

    fetchHospitals();
  }, []);

  useEffect(() => {
    const fetchSurgeons = async () => {
      try {
        const response = await surgeonService.getSurgeonsList({ search: "" });
        const surgeonOptions = response.data.map((surgeon) => ({
          value: surgeon._id,
          label: surgeon.first_name + " " + surgeon.last_name,
        }));
        setSurgeons(surgeonOptions);
      } catch (err) {
        console.error("Error fetching surgeons:", err);
      }
    };

    fetchSurgeons();
  }, []);

  useEffect(() => {
    const fetchHospitalDetails = async (id) => {
      try {
        const response = await bookingService.getHospitalDetailsById(id);
        const { addresses, departments } = response.data;
        if (addresses.length > 0) {
          addresses[0].checked = true;
        }
        if (departments.length > 0) {
          departments[0].checked = true;
        }

        setSelectedHospitalData(response.data);
        setHospitalAddress(addresses);
        setHospitalDepartment(departments);
      } catch (err) {
        console.error("Error fetching hospital details:", err);
      }
    };

    if (selectedHospital) {
      fetchHospitalDetails(selectedHospital.value);
    }
  }, [selectedHospital]);

  const handleBookingAddress = (checked, index) => {
    const updatedAddresses = hospitalAddress.map((addr, i) =>
      i === index ? { ...addr, checked } : addr
    );
    setHospitalAddress(updatedAddresses);
  };

  const handleBookingDepartment = (checked, index) => {
    const updatedDepartments = hospitalDepartment.map((dept, i) =>
      i === index ? { ...dept, checked } : dept
    );
    setHospitalDepartment(updatedDepartments);
  };

  const handleBookingInfo = (field, value) => {
    setBookingInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleDeliveryDateChange = (date) => {
    setBookingInfo((prevState) => ({
      ...prevState,
      delivery_date: date,
    }));
  };

  return (
    <div className="d-card">
      <h4>Basic Details</h4>
      <div className="form-group">
        <label>
          Hospital Name <span className="text-danger">*</span>
        </label>
        <div className="info position_up" style={{ zIndex: 8 }}>
          <Select
            value={selectedHospital}
            onChange={setSelectedHospital}
            options={hospitals}
            maxMenuHeight={190}
          />
          {validationErrors.hospitalId && (
            <span className="text-danger">Please select hospital name</span>
          )}
        </div>
      </div>

      {selectedHospital ? (
        <div className="row position-relative">
          <div className="info col-md-6">
            <h6>Address</h6>
            <hr />
            {hospitalAddress?.map((item, ind) => (
              <div
                className="info-left"
                style={{ marginLeft: "1.25rem" }}
                key={item._id}
              >
                <Input
                  type="radio"
                  id={item._id}
                  name="hospitalAddress"
                  value={item.name}
                  checked={item.checked}
                  onChange={(e) => handleBookingAddress(e.target.checked, ind)}
                />
                <strong> {selectedHospitalData.contact_person_name}</strong>
                <div>
                  <small>Location: {item.name}</small>
                </div>
                <div>
                  <small>Email: {selectedHospitalData.email}</small>
                </div>
                <div>
                  <small>
                    Phone: {selectedHospitalData.contact_person_phone_number}
                  </small>
                </div>
              </div>
            ))}
          </div>
          <div className="info col-md-6">
            <h6>Department</h6>
            <hr />
            {hospitalDepartment?.map((item, ind) => (
              <div
                className="info-left"
                style={{ marginLeft: "1.25rem" }}
                key={item._id}
              >
                <Input
                  type="radio"
                  id={item._id}
                  name="hospitalDepartment"
                  value={item.name}
                  checked={item.checked}
                  onChange={(e) =>
                    handleBookingDepartment(e.target.checked, ind)
                  }
                />
                <div>
                  <small>{item.name}</small>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-12">
            <div className="form-group mt-3">
              <span>
                <Input
                  type="checkbox"
                  className="ml-0 form-check-input"
                  checked={bookingInfo.pickup_location_checkbox}
                  onChange={(e) =>
                    handleBookingInfo(
                      "pickup_location_checkbox",
                      e.target.checked
                    )
                  }
                />
              </span>
              <label className="ml-4">
                Is Pickup Location And Contact Details Different
              </label>
              {bookingInfo.pickup_location_checkbox && (
                <Input
                  type="text"
                  className="form-control"
                  value={bookingInfo.pickup_location_text}
                  onChange={(e) =>
                    handleBookingInfo("pickup_location_text", e.target.value)
                  }
                />
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="form-group mt-2">
        <label>
          Surgeon Name <span className="text-danger">*</span>
        </label>
        <div className="info position_up" style={{ zIndex: 7 }}>
          <Select
            value={selectedSurgeon}
            onChange={setSelectedSurgeon}
            options={surgeons}
            maxMenuHeight={190}
            isMulti={true}
          />
          {validationErrors.surgeonData && (
            <span className="text-danger">Please select surgeon name</span>
          )}
        </div>
      </div> 

      <div className="form-group mt-2">
        <label>
          Delivery Date (minimum 6 weeks) - has to be confirmed by office <span className="text-danger">*</span>
        </label>
        <div className="inputdata formDatess">
        <DatePicker
          selected={bookingInfo.delivery_date}
          onChange={handleDeliveryDateChange}
          className="form-control"
          dateFormat="yyyy/MM/dd"
        />
        </div>
        {validationErrors.delivery_date && (
          <span className="text-danger">Please select a delivery date</span>
        )}
      </div>

      <div className="form-group mt-2">
        <label>Note </label>
        <Input
          type="textarea"
          value={bookingInfo.note}
          onChange={(e) => handleBookingInfo("note", e.target.value)}
          className="form-control"
        />
         {validationErrors.note && (
            <span className="text-danger">Please add a note</span>
          )}
      </div>

    </div>
  );
};

export default BasicDetails;
