import React, { Component } from "react";
import logo from "../assets/images/logo.png";
import { connect } from "react-redux";
import { authActions } from "../actions";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

class Signin extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(authActions.logout());
    this.state = {
      username: "",
      password: "",
      otp: "",
      email: "",
      submitted: false,
      isOtpSent: false,
      otpMessage: "",
      errorMessage: "",
      user : JSON.parse(localStorage.getItem("user"))

    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

 
  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
  
        dispatch(authActions.login(username, password))
            .then(user => {
                console.log("OTP request response:", user); 
                this.setState({ 
                    isOtpSent: true, 
                    otpMessage: user?.message,
                    email: username, 
                    errorMessage:""
                });       


                
            })
            .catch(error => {
                console.log("Error during OTP request:", error, error.response?.data?.message);
                this.setState({
                    errorMessage: error.message || error?.common
                });
            });
    
}

async handleOtpSubmit(e) {
  e.preventDefault();
  const { otp, email } = this.state;
  const { dispatch } = this.props;
  this.setState({ errorMessage: "" }); 

      try {
          const response = await dispatch(authActions.verifyOtp(email, otp));
          if (response.status === 200) {
              console.log("OTP verified successfully:", response);
          }
      } catch (error) {
          console.log("Error during OTP verification:", error);
          this.setState({
            errorMessage: error.message || error?.common
          });
      }
}

     
  handleBack() {
    this.setState({ isOtpSent: false, otp: "", otpMessage: "", errorMessage:"" });
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleOtpChange(e) {
    this.setState({ otp: e.target.value });
  }

  render() {
    const { error } = this.props;
    const { username, password, otp, isOtpSent, otpMessage, errorMessage } = this.state;

    if (this.state?.user && this.state?.user?.data?.user_role === "admin") {
      return <Redirect to="/admin/dashboard/booking-dispatch" />;
    } else if (this.state?.user && this.state?.user?.data?.user_role === "reps") {
      return <Redirect to="/reps/dashboard" />;
    }

    return (
      <div className="fw authentication_area">
        <div className="fw__right">
          <div className="text-center">
            <img src={logo} className="logo_1" alt="Logo" />
            <h4 className="mt-5">Account Login</h4>
          </div>
         
          {!isOtpSent ? (
            <div className="mt-5">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={username}
                  onChange={this.handleUsernameChange}
                />
                {error ? (
                  <span className="text-danger">
                    {error.email ? error.email : ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={password}
                  onChange={this.handlePasswordChange}
                />
                {error ? (
                  <span className="text-danger">
                    {error.password ? error.password : ""}
                  </span>
                ) : (
                  ""
                )}
                {errorMessage && <span className="text-danger">{errorMessage}</span>}
              </div>
              <Link to="/forgot-password">
                <small>Forgot Password?</small>
              </Link>
              <button
                className="btn btn-primary btn-block mt-5"
                onClick={this.handleSubmit}
              >
                Login
              </button>
            </div>

          ) : (
            <div className="mt-5">
              <div className="form-group">
                <label className="green-text" htmlFor="otpInput">{otpMessage}</label>
                <input
                  type="text"
                  className="form-control"
                  id="otpInput"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={this.handleOtpChange}
                />
                {errorMessage && <span className="text-danger">{errorMessage}</span>}
              </div>
              <div className="d-flex">
                <button className="btn btn-secondary" onClick={this.handleBack}>
                  Back
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={this.handleOtpSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user, error } = state.rootReducer.authentication;
  return {
    loggingIn,
    user,
    error,
  };
}

export default connect(mapStateToProps)(Signin);
